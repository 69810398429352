// Dependencies
import React, { useEffect, useState } from 'react'
import 'react-tabs/style/react-tabs.css';
import requestHit  from '../services/api.service'
import { toast } from 'react-toastify';

// Pagination component
function App({data}) {  
    const [record, setRecord] = useState(null)    

    useEffect(()=>{
        (()=>{
            if(data){                
                setRecord(data);
            }
        })()
    }, [data])

    const handleCommon = event => {
        const [propertyName, index, nestedProperty, nameee] = event.target.name.split(".");
        if(index && nestedProperty){
            setRecord((previous) => {
                previous.account_sizes[index] [nestedProperty]= {
                    ...previous.account_sizes[index][nestedProperty],
                    [nameee]: event.target.value,
                };
                console.log(previous)
                return { ...previous, [propertyName]: previous.account_sizes };
            });
        }else{
            let names = event.target.name
            setRecord(previous => {
            return { ...previous, [names]: event.target.value }
            })
        }        
    }

    const saveRecord = ()=>{
        requestHit.requestApiHit('post', `admin/updateTradingRules`, record).then((res) => {
            if(res.status){
                toast.success(res.message);
            }else{
                toast.error(res.message);
            }
        })
    }

    const deleteRecord = ()=>{
        requestHit.requestApiHit('post', `admin/deleteTradingRules`, {_id: record?._id}).then((res) => {
            if(res.status){
                toast.success(res.message);
            }else{
                toast.error(res.message);
            }
        })
    }

    const handleAdd = (phase)=>{
        let newSize = {
            "size" : '',
            "charge" : '',
            "profit_target" : '',
            "daily_loss_limit" : '',
            "max_loss_limit" : '',
            "minimum_trade_days" : '',
            "leverage" : 0
        }
        setRecord((previous) => ({
            ...previous,
            account_sizes: [...previous.account_sizes, newSize],
        }));
    }

    const handleDelete = (index)=>{       
        setRecord((previous) => ({
            ...previous,
            account_sizes: previous.account_sizes.filter((_, i) => i !== index),
        }));
    }

    return (
        <div className='container-fluid p-4'>
            <div className='row mb-4'>
                <div className="d-flex">
                    <div className="">
                        <div className='label'>Account Type</div>
                        <div className=''>
                            <input type='text' name='account_type' value={record?.account_type} className='' onChange={e => { handleCommon(e) }}/>
                        </div>
                    </div>
                    <div className="ms-3">
                        <div className='label'>Duration (In Days)</div>
                        <div className=''>
                            <input type='number' name='duration' value={record?.duration} className='form' onChange={e => { handleCommon(e) }} />
                        </div>
                    </div>
                </div>
                
            </div>

            <div className='row mb-4'>
                    <div className=' pt-4 pb-4 text-white phase-bg'>

                        <h1>Regular Phase</h1>
                        
                        {
                            record?.account_sizes["0"].map((sizeDetail, index)=>(
                                <div className='' key={index}  >
                        
                                    <div className="card">
                                        <div class='row'>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'>Size :</div>
                                            <input type='number' className='w-100' name={`account_sizes.0.${index}.size`} value={sizeDetail.size} onChange={e => { handleCommon(e) }}/>
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Charge : </div>
                                        <input type='number'  className='w-100' name={`account_sizes.0.${index}.charge`} value={sizeDetail.charge} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Profit Target : </div>
                                            <input type='number'  className='w-100' name={`account_sizes.0.${index}.profit_target`} value={sizeDetail.profit_target} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Daily Lose: </div>
                                            <input type='number'  className='w-100' name={`account_sizes.0.${index}.daily_loss_limit`} value={sizeDetail.daily_loss_limit} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'>  Max Lose : </div>
                                            <input type='number'  className='w-100' name={`account_sizes.0.${index}.max_loss_limit`} value={sizeDetail.max_loss_limit} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Min Trade Days : </div>
                                            <input type='number'  className='w-100' name={`account_sizes.0.${index}.minimum_trade_days`} value={sizeDetail.minimum_trade_days} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Leverage : </div>
                                            <input type='number'  className='w-100' name={`account_sizes.0.${index}.leverage`} value={sizeDetail.leverage} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        {/* <div className='col-md-1 mt-3'>
                                            <button className='btn-1' onClick={(e)=>{ handleDelete(0, index) }} >Delete</button>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        {/* <div className='row'>
                            <div className='col-md-3'>
                                <button className='btn-1' onClick={(e)=>{ handleAdd(0) }}>Add</button>
                            </div>
                        </div> */}
                    </div>
                    
            </div>


            <div className='row mb-4'>
                    <div className=' pt-4 pb-4 text-white phase-bg'>                        
                        <h1>Super Phase</h1>
                        
                        {
                            record?.account_sizes["1"].map((sizeDetail, index)=>(
                                <div className='' key={index}  >
                        
                                    <div className="card">
                                        <div class='row'>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'>Size :</div>
                                            <input type='number' className='w-100' name={`account_sizes.1.${index}.size`} value={sizeDetail.size} onChange={e => { handleCommon(e) }}/>
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Charge : </div>
                                        <input type='number'  className='w-100' name={`account_sizes.1.${index}.charge`} value={sizeDetail.charge} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Profit Target : </div>
                                            <input type='number'  className='w-100' name={`account_sizes.1.${index}.profit_target`} value={sizeDetail.profit_target} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Daily Lose: </div>
                                            <input type='number'  className='w-100' name={`account_sizes.1.${index}.daily_loss_limit`} value={sizeDetail.daily_loss_limit} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'>  Max Lose : </div>
                                            <input type='number'  className='w-100' name={`account_sizes.1.${index}.max_loss_limit`} value={sizeDetail.max_loss_limit} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Min Trade Days : </div>
                                            <input type='number'  className='w-100' name={`account_sizes.1.${index}.minimum_trade_days`} value={sizeDetail.minimum_trade_days} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        <div className='col-md-3 mb-1'>
                                            <div className='label'> Leverage : </div>
                                            <input type='number'  className='w-100' name={`account_sizes.1.${index}.leverage`} value={sizeDetail.leverage} onChange={e => { handleCommon(e) }} />
                                        </div>
                                        {/* <div className='col-md-1 mt-3'>
                                            <button className='btn-1' onClick={(e)=>{ handleDelete(1, index) }} >Delete</button>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        {/* <div className='row'>
                            <div className='col-md-3'>
                                <button className='btn-1' onClick={(e)=>{ handleAdd(1) }}>Add</button>
                            </div>
                        </div> */}
                    </div>
                    
            </div>
            
            <div className='row'>
                <div className='col-12'>
                    <button onClick={()=> saveRecord() } className='btn-1 mt-4'>Submit</button>
                    <button onClick={()=> deleteRecord() } className='btn-1 mt-4 mx-4'>Delete</button>
                </div>
            </div>
        </div>
    );
}
export default App
