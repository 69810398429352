import React, { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import Protected from './components/Protected'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Users from './pages/users'
import Tradings from './pages/TradingRules'
import UpgradeChallenge from './pages/UpgradeChallenge'
import Challanges from './pages/Challanges'
import PayHistory from './pages/PayHistory'
import PayInHistory from './pages/PayInHistory'
import 'react-toastify/dist/ReactToastify.css'
import Faq from './cms/Faq'
import PrivacyPolicy from './cms/PrivacyPolicy'
import TermsCondition from './cms/TermsCondition'
import Profile from './pages/Profile'
import KycVerfication from './pages/KycVerfication'
import Certificate from './pages/Certificate'
import Configuration from './pages/Configuration'

function App () {
  const navigate =  useNavigate();
  const location = useLocation();
  const [isSignedIn, setSignedIn] = useState(null);

  useEffect(()=>{
    (async ()=>{
      const token = await localStorage.getItem("token")
      if(token){   
        if(location.pathname === '/'){
          navigate('/dashboard')
        }    
        setSignedIn(true)
      }else{
        setSignedIn(false)
      }
    })()
  },[location.pathname, navigate])

  return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <Protected isSignedIn={isSignedIn}>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/users/:id?"
            element={
              <Protected isSignedIn={isSignedIn}>
                <Users />
              </Protected>
            }
          />          
          <Route
            path="/challange"
            element={
              <Protected isSignedIn={isSignedIn}>
                <Challanges />
              </Protected>
            }
          />
          <Route
            path="/trading/:id?"
            element={
              <Protected isSignedIn={isSignedIn}>
                <Tradings />
              </Protected>
            }
          />
          <Route
            path="/payInHistory/:id?"
            element={
              <Protected isSignedIn={isSignedIn}>
                <PayInHistory />
              </Protected>
            }
          />
          <Route
            path="/payHistory/:id?"
            element={
              <Protected isSignedIn={isSignedIn}>
                <PayHistory />
              </Protected>
            }
          />
          <Route
            path="/faq/:id?"
            element={
              <Protected isSignedIn={isSignedIn}>
                <Faq />
              </Protected>
            }
          />
          <Route
            path="/privacyPolicy"
            element={
              <Protected isSignedIn={isSignedIn}>
                <PrivacyPolicy />
              </Protected>
            }
          />
          <Route
            path="/termsCondition"
            element={
              <Protected isSignedIn={isSignedIn}>
                <TermsCondition />
              </Protected>
            }
          />
          <Route
            path="/profile"
            element={
              <Protected isSignedIn={isSignedIn}>
                <Profile />
              </Protected>
            }
          />
          <Route
            path="/upgradeChallenge/:id?"
            element={
              <Protected isSignedIn={isSignedIn}>
                <UpgradeChallenge />
              </Protected>
            }
          />
          <Route
            path="/kycVerfication/:id?"
            element={
              <Protected isSignedIn={isSignedIn}>
                <KycVerfication />
              </Protected>
            }
          />
          <Route
            path="/certificate/:id?"
            element={
              <Protected isSignedIn={isSignedIn}>
                <Certificate />
              </Protected>
            }
          />
          <Route
            path="/configuration/:id?"
            element={
              <Protected isSignedIn={isSignedIn}>
                <Configuration />
              </Protected>
            }
          />
        </Routes>
        
        
  )
}

export default App
