import Layout from '../components/Layout'
import { useEffect, useState } from 'react';
import apiService from "../services/api.service";

function App() {

  const [validUser, setValidUser] = useState(false);
  const [users, setUsers] = useState([]);

    useEffect(() => {
        apiService.requestApiHit('post', 'admin/getUserList', {}).then((res) => {
            if (res.status) {
                setUsers(res.data.splice(0, 5));
                setValidUser(true);
            } else {
                console.log("Data fetch failed!")
            }
        }).catch((e) => {
            console.error(e)
        })
    }, [])

  return (    
    <>
    {
        validUser ? (
            <Layout>        
                <main className="content">
                    <div className="container-fluid p-0">
        
                        <h1 className="h1 mb-3 fw-bold"><strong>Analytics</strong> Dashboard</h1>
        
                        <div className="row">
                            <div className="col-xl-6 col-xxl-5 d-flex">
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">Sales</h5>
                                                        </div>
        
                                                        <div className="col-auto">
                                                            <div className="text-primary">
                                                                <i className="align-middle" data-feather="truck"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="numb-highl  mt-1 mb-3 fw-bold">2.382</span>
                                                    <div className="mb-0">
                                                        <span className="text-danger"> <i
                                                                className="mdi mdi-arrow-bottom-right"></i> -3.65% </span>
                                                        <span className="text-muted">Since last week</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">Visitors</h5>
                                                        </div>
        
                                                        <div className="col-auto">
                                                            <div className="text-primary">
                                                                <i className="align-middle" data-feather="users"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="numb-highl  mt-1 mb-3 fw-bold">14.212</span>
                                                    <div className="mb-0">
                                                        <span className="text-success"> <i
                                                                className="mdi mdi-arrow-bottom-right"></i> 5.25% </span>
                                                        <span className="text-muted">Since last week</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">Earnings</h5>
                                                        </div>
        
                                                        <div className="col-auto">
                                                            <div className="text-primary">
                                                                <i className="align-middle" data-feather="dollar-sign"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="numb-highl mt-1 mb-3">$21.300</span>
                                                    <div className="mb-0">
                                                        <span className="text-success"> <i
                                                                className="mdi mdi-arrow-bottom-right"></i> 6.65% </span>
                                                        <span className="text-muted">Since last week</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col mt-0">
                                                            <h5 className="card-title">Orders</h5>
                                                        </div>
        
                                                        <div className="col-auto">
                                                            <div className="text-primary">
                                                                <i className="align-middle" data-feather="shopping-cart"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className="numb-highl  mt-1 mb-3">64</span>
                                                    <div className="mb-0">
                                                        <span className="text-danger"> <i
                                                                className="mdi mdi-arrow-bottom-right"></i> -2.25% </span>
                                                        <span className="text-muted">Since last week</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div className="col-xl-6 col-xxl-7">
                                <div className="card flex-fill w-100">
                                    <div className="card-header">
        
                                        <h5 className="card-title mb-0">Recent Movement</h5>
                                    </div>
                                    {/* <div className="card-body py-3">
                                        <div className="chart chart-sm">
                                            <canvas id="chartjs-dashboard-line"></canvas>
                                        </div>
                                    </div> */}
                                    <div className='table-responsive'>
                                        <table className="table table-hover my-0">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th className="">Amount</th>
                                                        <th className="">Profit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {users && users.map((data, index) =>
                                                        <tr key={index}>
                                                            <td>{data.first_name + " " + data.last_name}</td>
                                                            <td className="">{data.email}</td>
                                                            <td className="">{data.phonenumber}</td>
                                                            
                                                        </tr>
                                                    )}
                                                </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
        
        
                        <div className="row">
                            <div className="col-12 col-lg-8 col-xxl-9 d-flex">
                                <div className="card flex-fill">
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Recent Users</h5>
                                    </div>
                                    <div className='table-responsive'>
                                    <table className="table table-hover table-responsive my-0">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th className="">E-Mail</th>
                                                    <th className="">Phone</th>
                                                    <th className="">Phase</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users && users.map((data, index) =>
                                                    <tr key={index}>
                                                        <td>{data.first_name + " " + data.last_name}</td>
                                                        <td className="">{data.email}</td>
                                                        <td className="">{data.phonenumber}</td>
                                                        <td className="">{data.phase}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                    </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 col-xxl-3 d-flex">
                                <div className="card flex-fill w-100">
                                    <div className="card-header">
        
                                        <h5 className="card-title mb-0">Monthly Sales</h5>
                                    </div>
                                    <div className="card-body d-flex w-100">
                                        <div className="align-self-center chart chart-lg">
                                            <canvas id="chartjs-dashboard-bar"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                    </div>
                </main>
            </Layout>   
        ) : (
            <></>
        )
    }
    
    </>     
  );
}

export default App;
