// Dependencies
import React, { useEffect, useState } from 'react'
import 'react-tabs/style/react-tabs.css';
import requestHit  from '../services/api.service'
import { toast } from 'react-toastify';

// Pagination component
function App({data}) {  
    const [record, setRecord] = useState({})    

    useEffect(()=>{
        (()=>{
            let record = {
                _id : data._id,
                category: data.category,
                title: data.title,
                question: data.question,
                content: data.content

            }
            setRecord(record);
        })()
    }, [data])

    const handleCommon = event => {        
        let names = event.target.name
        setRecord(previous => {
            return { ...previous, [names]: event.target.value }
        })  
    }

    const saveRecord = ()=>{
        let saveUrl = record._id ? 'admin/updateFaq' : 'admin/addFaq'
        
        requestHit.requestApiHit('post', saveUrl, record).then((res) => {
            if(res.status){
                toast.success(res.message);
            }else{
                toast.error(res.message);
            }
        })
    }

    const deleteRecord = ()=>{
        requestHit.requestApiHit('post', `admin/deleteFaq`, {_id: record._id}).then((res) => {
            if(res.status){
                toast.success(res.message);
            }else{
                toast.error(res.message);
            }
        })
    }

    return (
        <div className='container-fluid bg-white p-4'>

            <div className='row'>
                <div className='col-md-12 mb-4'><h2>{record._id? 'Edit' : 'Add New' }  Faq Details</h2></div>                
            </div>
            <div className='row mb-4'>
                <div className='col-md-2'> Category </div>
                <div className='col-md-6'>
                    <input type='text' name='category' value={record.category} className='w-100 h-100' onChange={e => { handleCommon(e) }}/>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-md-2'> Title </div>
                <div className='col-md-6'>
                    <input type='text' name='title' value={record.title} className='w-100 h-100' onChange={e => { handleCommon(e) }}/>
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-md-2'>Question</div>
                <div className='col-md-6'>
                    <input type='text' name='question' value={record.question} className='w-100 h-100' onChange={e => { handleCommon(e) }} />
                </div>
            </div>
            <div className='row mb-4'>
                <div className='col-md-2'>Answer</div>
                <div className='col-md-6'>
                    <textarea type='text' name='content' value={record.content} className='w-100 h-100' onChange={e => { handleCommon(e) }} ></textarea>
                </div>
            </div>

            <div className='row'>
                <div className='col-12'>
                    <button onClick={()=> saveRecord() } className='btn btn-primary mt-4'>Submit</button>
                    { record._id && ( <button onClick={()=> deleteRecord() } className='btn btn-primary mt-4 mx-4'>Delete</button> )
                    }
                </div>
            </div>
        </div>
    );
}
export default App
