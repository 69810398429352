import {useEffect, useState} from 'react'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import EditKycVerficationDetail from '../components/EditKycVerficationDetail'
import { useParams } from 'react-router-dom'
import requestHit  from '../services/api.service'

function App() {
    
    const pageParams = useParams()
    const accountId = pageParams.id
    const url = 'admin/getPayIn'
    const [accountDetails, setAccountDetails] = useState([]);

    const detailsInfo = [{ "Name": "first_name" }, { "Email": "email" }, { "Order Id": "order_id" }, { "Payment Id" : "unique_key" }, { "Status": "status" }];


    useEffect(()=>{

        if(accountId){
            requestHit.requestApiHit('post', `${url}`, {_id : accountId}).then((res) => {
                if (res.data) {
                    setAccountDetails(res.data[0]);
                    console.log(res.data[0]);
                } 
            })
        }

    }, [accountId])
    
    return (
       <Layout>
            {
                !accountId ? (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <h1 className="h1 mb-3"><strong>Certificate</strong> History List</h1>
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card w-100">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">User Name</th>
                                                <th scope="col">User ID</th>
                                                <th scope="col">Phase 1</th>
                                                <th scope="col">Phase 2</th>
                                                <th scope="col">Phase 3</th>
                                                <th scope="col">Certificate Download</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Mark</td>
                                                    <td>123456</td>
                                                    <td>Pending</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>None</td>
                                                    <td>Pending</td>
                                                    <td><button className="btn-1 btn-sm mt-4">Edit</button></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Mark</td>
                                                    <td>123456</td>
                                                    <td>Completed</td>
                                                    <td>Pending</td>
                                                    <td>-</td>
                                                    <td>Up To Phase:1</td>
                                                    <td>Pending</td>
                                                    <td><button className="btn-1 btn-sm mt-4">Edit</button></td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Mark</td>
                                                    <td>123456</td>
                                                    <td>Completed</td>
                                                    <td>Completed</td>
                                                    <td>Pending</td>
                                                    <td>Up To Phase:2</td>
                                                    <td>Pending</td>
                                                    <td><button className="btn-1 btn-sm mt-4">Edit</button></td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>Mark</td>
                                                    <td>123456</td>
                                                    <td>Completed</td>
                                                    <td>Completed</td>
                                                    <td>Pending</td>
                                                    <td>Up To Phase:2</td>
                                                    <td>Pending</td>
                                                    <td><button className="btn-1 btn-sm mt-4">Edit</button></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                ) : (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <EditKycVerficationDetail data={accountDetails} pageFor={'payInHistory'} />
                            </div>
                        </div>
                    </main>
                )
            }
        </Layout>
    );
}

export default App;
