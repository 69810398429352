// Dependencies
import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import requestHit  from '../services/api.service'


// Pagination component
function App({ header, url, pageFor, searchQuery = '', detailsNeed = false}) {  

  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const pageSize = 10;

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };  


  // Render page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalData / pageSize); i++) {
    pageNumbers.push(i);
  }

  // Render pagination links
  const renderPaginationLinks = pageNumbers.map((pageNumber) => (
    <button key={pageNumber} className={currentPage === pageNumber ? 'active' : '' } onClick={() => handlePageChange(pageNumber)}>
      {pageNumber}
    </button>
  ));

  const searchQueryChange = useCallback(async()=>{
    if(searchQuery){
      setCurrentPage(1);
    }
  }, [searchQuery])


  const getData = useCallback(async()=>{
    const payload = searchQuery ? { search : searchQuery } : {}
    requestHit.requestApiHit('post', `${url}/${currentPage}`, payload).then((res) => {
      if (res.data) {
        setCurrentPageData(res.data);
        setTotalData(res.count);
      } else {
          console.log("Data fetch failed!")
      }
    }).catch((e) => {
        console.error(e)
    })
  }, [url, currentPage, searchQuery])


  useEffect(() => {
    getData();
    searchQueryChange();
  }, [getData, searchQueryChange])

  const handleRowClick = (id)=>{
    if( detailsNeed ) navigate(`/${pageFor}/${id}` ) 
  }

  return (
    <div>
      <table className="table table-hover table-responsive my-0">
        <thead>
            <tr>
              {
                header.map((info, index)=>(
                  <th key={index}>{Object.keys(info)}</th>
                ))
              }
            </tr>
        </thead>
        <tbody>
            {currentPageData && currentPageData.map((data, dataIndex) =>
                  <tr className='cursor-pointer' key={dataIndex} onClick={()=> handleRowClick(data._id) } >
                      { header.map((info, index)=>(
                        <td key={index}>{data[Object.values(info)]}</td>
                      )) }
                  </tr>
            )}

            {
              currentPageData && currentPageData.length === 0 && (
                <tr>
                  <td colSpan={header.length+1} className='text-center'>Sorry no {pageFor} deatils found! </td>
                </tr>
              )
            }

            {
              !currentPageData && (
                <tr>
                  <td colSpan={header.length+1} className='text-center'>Searching {pageFor} deatils... </td>
                </tr>
              )
            }
        </tbody>
      </table>

      {
        pageNumbers && pageNumbers.length > 1 ? (
          <nav aria-label="Page navigation">
            <ul className="pagination">
              {renderPaginationLinks}
            </ul>
          </nav>
        ) : (
          <></>
        )
      }      
    </div>
  );
}
export default App
