import { Link } from 'react-router-dom'

function Footer(){
    return(
        <footer className="footer">
            <div className="container-fluid">
                <div className="row text-muted">
                    <div className="col-6 text-start">
                        <p className="mb-0">
                            <Link className="text-muted" ><strong>GFF</strong></Link> &copy;
                        </p>
                    </div>
                    
                </div>
            </div>
        </footer>
    )
}

export default Footer