import {useEffect, useState} from 'react'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import EditKycVerficationDetail from '../components/EditKycVerficationDetail'
import { useParams } from 'react-router-dom'
import requestHit  from '../services/api.service'

function App() {
    
    const pageParams = useParams()
    const accountId = pageParams.id
    const url = 'admin/getPayIn'
    const [accountDetails, setAccountDetails] = useState([]);

    const detailsInfo = [{ "Name": "first_name" }, { "Email": "email" }, { "Order Id": "order_id" }, { "Payment Id" : "unique_key" }, { "Status": "status" }];


    useEffect(()=>{

        if(accountId){
            requestHit.requestApiHit('post', `${url}`, {_id : accountId}).then((res) => {
                if (res.data) {
                    setAccountDetails(res.data[0]);
                    console.log(res.data[0]);
                } 
            })
        }

    }, [accountId])
    
    return (
       <Layout>
            {
                !accountId ? (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <h1 className="h1 mb-3"><strong>Certificate</strong> History List</h1>
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card w-100">
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                  <label>Configuration</label>
                                                  <div className='mt-2'>
                                                        <input type='text' name='first_name'  className='w-50 p-2'/>
                                                  </div>
                                                  <div>
                                                    <button className="btn-1 mt-4">Edit</button>
                                                  </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                ) : (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <EditKycVerficationDetail data={accountDetails} pageFor={'payInHistory'} />
                            </div>
                        </div>
                    </main>
                )
            }
        </Layout>
    );
}

export default App;
