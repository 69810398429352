import {useEffect, useState} from 'react'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import Multitab from '../components/Multitab'
import { useParams } from 'react-router-dom'
import requestHit  from '../services/api.service'

function App() {
    
    const pageParams = useParams()
    const accountId = pageParams.id
    const url = 'admin/getHistory/challange'
    const [accountDetails, setAccountDetails] = useState([]);

    const detailsInfo = [{ "Name": "name" }, { "Login ID": "login" }, { "Phase": "Phase" }, { "Phase status": "phase_status" }, { "Service By": "server" }, { "Platform": "platform" }, { "Account Type" : "account_type" }, { "Account Price": "account_size" }, {  "Amount": "price" }, {  "Payment Status" : "payment_status" }];


    useEffect(()=>{

        if(accountId){
            requestHit.requestApiHit('post', `${url}`, {_id : accountId}).then((res) => {
                if (res.data) {
                    setAccountDetails(res.data[0]);
                    console.log(res.data[0]);
                } 
            })
        }

    }, [accountId])
    
    return (
        <Layout>
                {
                    !accountId ? (
                        <main className="content">
                            <div className="container-fluid p-0">
                                <h1 className="h1 mb-3"><strong>Analytics </strong>Challages</h1>
                                <div className="row">
                                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                        <div className="card w-100">
                                            <div className="card-header">
                                                <h5 className="card-title mb-0"> User Challage List</h5>
                                            </div>
                                            <div className='table-responsive'>
                                              <Pagination header={detailsInfo} url={ url } pageFor={'Challanges'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    ) : (
                        <main className="content">
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <Multitab data={accountDetails} />
                                </div>
                            </div>
                        </main>
                    )
                }
        </Layout>
    );
}

export default App;
