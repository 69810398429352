import {useEffect, useState} from 'react'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import EditPayDetail from '../components/EditPayDetail'
import { useParams } from 'react-router-dom'
import requestHit  from '../services/api.service'

function App() {
    
    const pageParams = useParams()
    const accountId = pageParams.id
    const url = 'admin/getPayouts'
    const [accountDetails, setAccountDetails] = useState([]);

    const detailsInfo = [{ "Name": "first_name" }, { "Email": "email" }, { "Mobile Number": "phonenumber" }, { "Account Number": "acc_no" }, { "IFSC code" : "ifsc_code" }, { "MICR code": "micr_code" }, {  "Amount": "amount" }, {  "Payment Status" : "payment_status" }];


    useEffect(()=>{

        if(accountId){
            requestHit.requestApiHit('post', `${url}`, {_id : accountId}).then((res) => {
                if (res.data) {
                    setAccountDetails(res.data[0]);
                    console.log(res.data[0]);
                } 
            })
        }

    }, [accountId])
    
    return (
       <Layout>
            {
                !accountId ? (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <h1 className="h1 mb-3"><strong>Analytics</strong> History List</h1>
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card w-100">
                                        <div className="card-header">
                                            <h5 className="card-title mb-0"> PayOut History</h5>
                                        </div>
                                        <div className='table-responsive'>
                                            <Pagination header={detailsInfo} url={ url } pageFor={'payHistory'} detailsNeed={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                ) : (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <EditPayDetail data={accountDetails} pageFor={'payHistory'} />
                            </div>
                        </div>
                    </main>
                )
            }
        </Layout>
    );
}

export default App;
