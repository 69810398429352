// Dependencies
import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import requestHit  from '../services/api.service'
import { toast } from 'react-toastify';

// Pagination component
function App({data}) {  
    const [profileDetails, setProfileDetails] = useState({})    
    const [challenges, setChallenges] = useState([1,2,3])

    useEffect(()=>{
        (()=>{
            let profileInfo = {
                '_id' : data._id,
                'first_name' : data.first_name,
                'last_name' : data.last_name,
                'email' : data.email,
                'email_verify' : data.email_verify,
                'phonenumber' : data.phonenumber,
                'status' : data.status
            }
            setChallenges(data.challenges);
            setProfileDetails(profileInfo);
        })()
    }, [data])

    const [tabIndex, setTabIndex] = useState(0);

    const handleCommon = event => {
        let names = event.target.name
        setProfileDetails(previous => {
          return { ...previous, [names]: event.target.value }
        })
    }

    const savePersonalInfo = ()=>{

        requestHit.requestApiHit('post', `admin/updateUser`, profileDetails).then((res) => {
            if(res.status){
                toast.success(res.message);
            }else{
                toast.error(res.message);
            }
        })
    }

    const deleteUser = ()=>{
        requestHit.requestApiHit('post', `admin/deleteUser`, {_id: profileDetails._id}).then((res) => {
            if(res.status){
                toast.success(res.message);
            }else{
                toast.error(res.message);
            }
        })
    }

    return (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
                <Tab>User Info</Tab>
                <Tab>Challenges</Tab>
            </TabList>
            <TabPanel>
               <div className='container-fluid card p-4'>
                    <div className='row mb-4'>
                        <div className="col-lg-4">
                            <div className='label'> First Name</div>
                            <div className=''>
                                <input type='text' name='first_name' value={profileDetails.first_name} className='w-100 p-2' onChange={e => { handleCommon(e) }}/>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='label'> Last Name</div>
                            <div className=''>
                                <input type='text' name='last_name' value={profileDetails.last_name} className='w-100 p-2' onChange={e => { handleCommon(e) }} />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='label'> Email</div>
                            <div className=''>
                                <input type='email' name='email' value={profileDetails.email} className='w-100 p-2' onChange={e => { handleCommon(e) }} />
                            </div>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <div className='label'>Contact</div>
                            <div className=''>
                                <input type='number' name='phone_number' value={profileDetails.phonenumber} className='w-100 p-2' onChange={e => { handleCommon(e) }} />
                            </div>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <div className='label'>Email verify</div>
                            <div className=''>
                                <select name='email_verify' value={profileDetails.email_verify} className='w-100 p-2' onChange={e => { handleCommon(e) }} >
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-4">
                            <div className='label'>Status</div>
                            <div className=''>
                                <select name='status' value={profileDetails.status}  className='w-100 p-2' onChange={e => { handleCommon(e) }}>
                                    <option value={1}>Active</option>
                                    <option value={0}>Deactive</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    

                    <div className='row'>
                        <div className='col-12'>
                            <button onClick={()=> savePersonalInfo() } className='btn-1 mt-3'>Submit</button>
                            <button onClick={()=> deleteUser() } className='btn-1 mt-4 mx-3'>Delete</button>
                        </div>
                    </div>
               </div>
            </TabPanel>
            <TabPanel>
            <div className=''>
                <div className='row mb-4'>
                {
                    challenges && challenges.map((challenge, index)=>(
                        <div className='' key={index}>
                            <div class='card'> 
                                <div className='row  m-2'>
                                    <div className="col-lg-4">
                                        <div class='label'>
                                            Login :
                                        </div>
                                        <div class='label'>
                                            <h4 className="text-white">{challenge.login} </h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class='label'>
                                            password :
                                        </div>
                                        <div class='label'>
                                            <h4 className="text-white">************* </h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class='label'>
                                        Server :
                                        </div>
                                        <div class='label'>
                                            <h4 className="text-white">{challenge.server}  </h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-3">
                                        <div class='label'>
                                        Platform :
                                        </div>
                                        <div class='label'>
                                            <h4 className="text-white">{challenge.platform}  </h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mt-3">
                                        <div class='label'>
                                        Size :
                                        </div>
                                        <div class='label'>
                                            <h4 className="text-white">{challenge.account_size}   </h4>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    ))
                }
                </div>
            </div>
            </TabPanel>
        </Tabs>
    );
}
export default App
