import {useEffect, useState} from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Pagination from '../components/Pagination'
import EditFaqDetails from '../components/EditFaqDetails'
import { Link, useParams, useNavigate } from 'react-router-dom'
import requestHit  from '../services/api.service'

function App() {
    const navigate = useNavigate();
    const pageParams = useParams()
    const paramId = pageParams.id
    const url = 'admin/getFaqList'
    const [particularDetails, setParticularDetails] = useState([]);

    const detailsInfo = [{ "Category": "category" }, { "Title": "title" }, { "Question" : "question" }, { "Content": "content" }];

    useEffect(()=>{

        if(paramId && paramId!=='addNew' ){
            requestHit.requestApiHit('post', `${url}`, {_id : paramId}).then((res) => {
                if (res.data) {
                    setParticularDetails(res.data[0]);
                } 
            })
        }else{
            let record = {
                _id : null,
                category: '',
                title: '',
                question: '',
                content: ''

            }
            setParticularDetails(record);
        }

    }, [paramId])
    

    return (
        <div className="wrapper">
            <Sidebar />

            <div className="main">
                <Navbar />

                {
                    !paramId ? (
                        <main className="content">
                            <div className="container-fluid p-0">
                                <h1 className="h3 mb-3">FAQ's</h1>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <button className='btn primary-btn' onClick={(e)=> navigate('/faq/addNew') } >Add New</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                        <div className="card w-100">
                                            <div className="card-header">
                                                <h5 className="card-title mb-0">FaQ</h5>
                                            </div>
                                            <Pagination header={detailsInfo} url={ url } pageFor={'faq'} searchQuery={''} detailsNeed={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    ) : (
                        <main className="content">
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <EditFaqDetails data={particularDetails}/>
                                </div>
                            </div>
                        </main>
                    )
                }

                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row text-muted">
                            <div className="col-6 text-start">
                                <p className="mb-0">
                                    <Link className="text-muted"><strong>GFF</strong></Link> &copy;
                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default App;
