import React, { useState } from 'react';
import Sidebar from './Sidebar.js';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Layout(props) {

    const [menuActive, setMenuActive] = useState(false)

    return (
        <div className="wrapper">
            <Sidebar menuActive={menuActive} />
            <div className="main">
                <Navbar setMenuActive={setMenuActive} />  
                <ToastContainer />
                {props.children}
                <Footer />
            </div>
        </div>
    );
}

export default Layout;