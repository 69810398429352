// Dependencies
import React, { useEffect, useState } from 'react'
import 'react-tabs/style/react-tabs.css';
import requestHit  from '../services/api.service'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

// Pagination component
function App({data,pageFor}) {  
    const [record, setRecord] = useState({})    
    const navigate = useNavigate();

    useEffect(()=>{
        (()=>{
            let record = {
                _id : data._id,
                phase_status: data.phase_status,
            }
            setRecord(record);
        })()
    }, [data])

    const handleCommon = event => {        
        let names = event.target.name
        setRecord(previous => {
            return { ...previous, [names]: event.target.value }
        })  
    }

    const saveRecord = ()=>{
        let saveUrl = 'admin/approveUpgradeRequest';
        
        requestHit.requestApiHit('post', saveUrl, record).then((res) => {
            if(res.status){
                toast.success(res.message);
                navigate(`/${pageFor}` )
            }else{
                toast.error(res.message);
                navigate(`/${pageFor}` )
            }
        })
    }


    return (
        <div className='container-fluid bg-white p-4'>

            <div className='row'>
                <div className='col-md-12 mb-4'><h2> Upgrade Challange</h2></div>                
            </div>
            <div className='row mb-4'>
                <div className='col-md-2'>
                    <select name='phase_status' value={record.phase_status}  className='w-100 h-100' onChange={e => { handleCommon(e) }}>
                        <option value={"Pending"}>Pending</option>
                        <option value={"Approve"}>Approve</option>
                    </select>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <button onClick={()=> saveRecord() } className='btn btn-primary mt-4'>Submit</button>
                </div>
            </div>
        </div>
    );
}
export default App
