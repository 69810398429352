import {useEffect, useState} from 'react'
import Pagination from '../components/Pagination'
import Multitab from '../components/Multitab'
import { useParams } from 'react-router-dom'
import requestHit  from '../services/api.service'
import Layout from '../components/Layout'

function App() {
    
    const pageParams = useParams()
    const accountId = pageParams.id
    const url = 'admin/getUserList'
    const [accountDetails, setAccountDetails] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [searchQuery, setSearchQuery] = useState("");

    const detailsInfo = [{ "Name": "name" }, { "Email" : "email" }, { "Contact": "phonenumber" }, {  "Phase": "phase" }, {  "Balance" : "account_balance" }, {  "Size": "account_size" }, {  "Avg Win": "avg_win" }, { "Avg Loss" : "avg_loss" }, {  "Win Ratio" : "win_ratio" }];


    useEffect(()=>{

        if(accountId){
            requestHit.requestApiHit('post', `${url}`, {_id : accountId}).then((res) => {
                if (res.data) {
                    setAccountDetails(res.data[0]);
                } 
            })
        }

    }, [accountId])
    
    return (
        <Layout>
            {
                !accountId ? (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <h1 className="h3 mb-3">Users</h1>
                            <div className="row mb-4">
                                <div className="col-md-6">
                                    <input type='text' placeholder='Search Username / Email' name='name' className='w-100 p-2' value={searchText} onChange={(e)=> setSearchText(e.target.value) }></input>
                                </div>
                                <div className="col-md-6 mt-sm-3 mt-md-0">
                                    <button className='btn primary-btn' onClick={(e)=> setSearchQuery(searchText) } >Search</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                                    <div className="card w-100">
                                        <div className=" card-header">
                                            <h5 className="card-title mb-0">Users</h5>
                                        </div>
                                        <div className='table-responsive'>
                                            <Pagination header={detailsInfo} url={ url } pageFor={'users'} searchQuery={searchQuery} detailsNeed={true} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                ) : (
                    <main className="content">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <Multitab data={accountDetails} />
                            </div>
                        </div>
                    </main>
                )
            }
        </Layout>
    );
}

export default App;
